<template>
  <div style="min-width: 1360px;">
    <new-cloude-header></new-cloude-header>
    <div class="number-change-box-outer">
      <div class="number-change-box">
        <div class="change-left">
          <div class="change-left-title">
            <span>关于我们</span>
          </div>
          <div class="change-left-container">
            <span>重庆帮企科技集团有限公司是一家以互联网为基础的国家级高新技术企业、重庆市企业技术中心、重庆“专精特新”企业。同时拥有IDC\CDN\ISP\ICP\EDI《增值电信业务经营许可证》，坚持“一心为企业服务”的宗旨，致力于降低企业数字化门槛，为企业提供小型化、快速化、轻量化、精准化的数字化产品。</span>
          </div>
        </div>
        <div class="change-right">
          <img class="img-item" :src="`${require('@/assets/img/newHoemImgs/1599.jpg')}`">
        </div>
      </div>
    </div>


    <div class="container-box-one">
      <div style="margin-top: 40px">
        <div class="all-title">全国业务范围</div>
      </div>
      <div class="map-box">
        <div class="map-box-left">
          <el-collapse v-model="activeNames" @change="handleChange">
            <el-collapse-item :title="item.shortName" :name="item.cityCode" v-for="item in addressDataList"
                              :key="item.cityCode">
              <div class="address-str">{{ item.address }}</div>
              <div class="address-str">{{ item.phone }}</div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div>
          <all-map></all-map>
        </div>
      </div>
    </div>


    <div style="background-color: #F7F8FC;padding-bottom: 55px;">
      <div class="page-content">
        <div class="title">荣誉与资质</div>
        <div class="imgList">
          <div v-for="(src,i) in honnerImgList" :key="i">
            <el-image style="width: 290px; height: auto;" :src="src"
                      fit="contain"/>
          </div>
        </div>
        <div class="scroll-x">
          <!--                    <div class="scroll-x-h" style="width: 700px;height: 370px;" v-for="(row,i) in newsData" :key="i">-->
          <div class="scroll-x-h" style="width: 700px;" v-for="(row,i) in newsData" :key="i">
            <div class="scroll-x-h-top">
              <div class="left-line"/>
              <!--                            <div style="height: 270px;">-->
              <div>
                <div class="news-item" v-for="(item,index) in row.list" :key="index">
                  <i class="el-icon-caret-right" style="color:#1935DE;margin-right: 6px;"/>
                  <div class="news-item-title">{{ item.title }}</div>
                </div>
              </div>
              <div class="box-line"/>
              <div class="box-year">{{ row.title }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="padding-bottom: 90px;">
      <div class="page-content">
        <div class="title">技术成果</div>
        <div style="display: flex;align-items: center;">
          <el-image style="width: 840px; height: 340px;margin-right: 10px;"
                    :src="`${require('@/assets/img/home/1823.jpg')}`" fit="fill"/>
          <div style="margin-left: 50px;">
            <div class="certification-item">
              <img src="../../assets/img/home/1601.png" class="certification-icon">
              <div>专利</div>
              <div>{{ info.patent || 0 }}</div>
            </div>
            <div class="certification-item">
              <img src="../../assets/img/home/1602.png" class="certification-icon">
              <div>商标</div>
              <div>{{ info.trademark || 0 }}</div>
            </div>
            <div class="certification-item">
              <img src="../../assets/img/home/1600.png" class="certification-icon">
              <div>软件著作权</div>
              <div>{{ info.softwareCopyright || 0 }}</div>
            </div>
            <div class="certification-item">
              <img src="../../assets/img/home/1603.png" class="certification-icon">
              <div>资质证书</div>
              <div>{{ info.qualificationCertificate || 0 }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="padding-bottom: 90px;background-color: rgb(240, 242, 255)">
      <div class="page-content">
        <div class="title">工作动态</div>
        <div style="display: flex;align-items: center;">
          <div class="img-box">
            <el-image style="width: 580px; height: 340px;margin-right: 10px;" v-if="trendsImage"
                      :src="trendsImage" fit="fill"/>
            <div class="img-str">
              <span>{{ imgContainer }}</span>
            </div>
          </div>
          <div style="margin-left: 50px;">
            <div class="trends-list">
              <div class="trends-list-item" v-for="item in trendsDataList" :key="item.id"
                   @click="trenedClick(item)">
                <span class="trends-container">{{ item.title }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <new-cloude-footer></new-cloude-footer>
  </div>
</template>

<script>
import {companyHonor, companyInfo} from "@/api/defaultApi";
import {companyAllHonor, dynamicList, getProvinceList} from "@/api/newHomeApi";
import newCloudeHeader from "@/views/home/newCloudeHeader.vue";
import newCloudeFooter from "@/views/home/newCloudeFooter.vue";
import allMap from "@/components/map/map.vue"
export default {
  name: "aboutUsView",
  components: {newCloudeHeader, newCloudeFooter, allMap},
  data() {
    return {
      info: {},
      newsData: [],
      trendsDataList: [],
      trendsImage: '',
      addressDataList: [],
      activeNames: [],
      imgContainer: '',
      honnerImgList: []
    }
  },
  created() {
    this.getCompanyInfo();
    this.getDynamicList();
    this.getProvinceList();
    // this.getCompanyAllHonor();
  },
  methods: {
    //获取公司信息
    getCompanyInfo() {
      companyInfo({cityCode: 1, request: 'form'}).then(res => {
        if (res.code == 200) {
          this.info = res.content;
          if (this.info.honorImage) {
            this.honnerImgList = res.content.honorImage=this.info.honorImage.split(',')
          }
          // this.honnerImgList = res.content.honorImage
          this.getCompanyHonor();
        }
      })
    },
    //获取企业荣誉
    getCompanyHonor() {
      companyHonor({id: this.info.id, request: 'form'}).then(res => {
        if (res.code == 200) {
          for (let key in res.content) {
            let json = {};
            json.title = key;
            json.list = res.content[key]
            this.newsData.push(json)
          }
        }
      })
    },
    go(url) {
      window.open(url);
    },
    //工作动态列表
    getDynamicList() {
      dynamicList({
        "id": 0,
        "page": 1,
        "pageLimit": 5
      }).then(res => {
        if (res.code == 200) {
          this.trendsDataList = res.content.rows
          this.trendsImage = this.trendsDataList[0].image
          this.imgContainer = this.trendsDataList[0].title
        }
      })
    },
    //工作动态详情
    trenedClick(item) {
      this.$router.push({
        path: '/detailsView',
        query: {
          id: item.id
        }
      })
    },
    //所有省份地址
    getProvinceList() {
      getProvinceList({
        request: 'form'
      }).then(res => {
        if (res.code == 200) {
          this.addressDataList = res.content
        }
      })
    },
    // 获取全国荣誉
    getCompanyAllHonor() {
      companyAllHonor({
        request: 'form'
      }).then(res => {
        if (res.code == 200) {
          this.honnerImgList = res.content.honorImage
          for (let key in res.content.honorList) {
            let json = {};
            json.title = key;
            json.list = res.content.honorList[key]
            this.newsData.push(json)
          }
          this.info.patent = res.content.patent
          this.info.trademark = res.content.trademark
          this.info.softwareCopyright = res.content.softwareCopyright
          this.info.qualificationCertificate = res.content.qualificationCertificate
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.img-box {
  position: relative;

  .img-str {
    position: absolute;
    bottom: 0;
    left: 20px;
    font-size: 18px;
    font-family: 'SourceHanSansCN';
    font-weight: bold;
    color: #FFFFFF;
    line-height: 70px;
    text-align: center;
  }
}

.address-str {
  font-size: 16px;
  font-family: 'SourceHanSansCN';
  font-weight: 500;
  color: #333333;
  line-height: 24px;
}

.trends-list {
  width: 587px;

  .trends-list-item {
    cursor: pointer;
    border-bottom: 1px solid rgba(221, 223, 236);

    .trends-container {
      font-size: 18px;
      font-family: 'SourceHanSansCN';
      font-weight: 500;
      color: #333333;
      line-height: 60px;
    }
  }

}

.container-box-one {
  width: 1200px;
  margin: 0 auto;

  .map-box {
    display: flex;
    justify-content: space-between;


    .map-item {
      width: 830px;
    }
  }

  .all-title {
    font-size: 30px;
    font-family: 'SourceHanSansCN';
    font-weight: bold;
    color: #333333;
    text-align: center;
  }
}

.number-change-box-outer {
  background-image: url("~@/assets/img/newHoemImgs/guanyuwomen.png");
  background-repeat: no-repeat;

  background-size: cover;

  .number-change-box {
    width: 1200px;
    margin: 0 auto;
    padding: 50px 0;

    display: flex;
    justify-content: space-between;

    .change-left {
      width: 665px;


      .change-left-title {
        font-size: 30px;
        font-family: 'SourceHanSansCN';
        font-weight: bold;
        color: #FFFFFF;
      }

      .change-left-container {
        font-size: 18px;
        font-family: 'SourceHanSansCN';
        font-weight: 500;
        color: #FFFFFF;
        margin-top: 50px;
      }
    }

    .change-right {
      width: 538px;
      height: 352px;
      margin-left: 70px;


      .img-item {
        width: 100%;
        height: 100%;

      }
    }
  }
}

.banner-title {
  font-size: 28px;
  font-weight: bold;
  color: #fff;
}

.banner-content {
  color: #fff;
  margin-top: 34px;
  font-size: 18px;
  line-height: 1.8;
}

.page-content {
  width: 1200px;
  margin: auto;

  .title {
    font-size: 28px;
    font-weight: bold;
    padding: 30px 0;
  }

  .imgList {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    justify-content: space-between;
  }
}

.scroll-x::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

.scroll-x::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.2);
  background: #959CC3;
}

.scroll-x::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.2);
  background: #E4E6F1;
}

.scroll-x {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  //height: 650px;

  .scroll-x-h {
    display: inline-block;

    &-top {
      display: flex;
      align-items: stretch;
      position: relative;

      .left-line {
        width: 2px;
        background-color: #1935DE;
        margin-right: 14px;
      }

      .box-line {
        position: absolute;
        bottom: -20px;
        width: 100%;
        height: 1px;
        background-color: #D9DBEB;
      }

      .box-year {
        position: absolute;
        bottom: -60px;
        left: 30px;
        font-size: 24px;
        color: #333;
      }
    }
  }

  .news-item {
    display: flex;
    align-items: center;
    margin: 20px 0;
    font-size: 16px;
    color: #333;
    cursor: pointer;

    &-title {
      white-space: nowrap;
      width: 620px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.scroll-x-h:after {
  content: '';
  display: block;
  height: 85px;
}

.certification-item {
  display: flex;
  align-items: center;
  margin: 40px 0;

  > div:nth-child(2) {
    font-size: 18px;
    margin-right: 10px;
    font-weight: bold;
    color: #333;
  }

  > div:nth-child(3) {
    font-size: 36px;
    color: #1935DE;
    line-height: 1;
    font-weight: bold;
  }
}

.about-item {
  margin: 6px 0;
  font-size: 18px;
}

.certification-icon {
  margin-right: 20px;
  height: 18px;
}
</style>
<style scoped>
.map-box-left >>> .el-collapse {
  width: 300px;
  height: 520px;
  overflow-y: scroll;
}


.map-box-left >>> .el-collapse::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

.map-box-left >>> .el-collapse::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.2);
  background: #959CC3;
}

.map-box-left >>> .el-collapse::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.2);
  background: #E4E6F1;
}

.map-box-left >>> .el-collapse-item__header {
  background: rgba(247, 248, 252, 1);
  padding-left: 20px;
  font-size: 24px;
  font-family: 'SourceHanSansCN';
  font-weight: 500;
  color: #333333;
  height: 63px;
  line-height: 60px;
}

.map-box-left >>> .el-collapse-item__content {
  background: rgba(247, 248, 252, 1);
  padding-left: 20px;
  padding-bottom: 5px;
}

</style>
