import axios from "@/api/index";
//申请试用
export const serviceApplyTrial = data => axios.post('/smart/servicePlatformApply/serviceApplyTrial',data)
//行业类别
export const industry = params => axios.get('/smart/utils/industry',{params})
/*发送验证码*/
export const sendSmsVerificationCode=data=>axios.post('/smart/utils/sendSmsVerificationCode',data);
//公司信息
export const companyInfo = params => axios.get('/smart/backgroundCompany/companyInfo',{params})
//公司荣誉
export const companyHonor = params => axios.get('/smart/backgroundCompany/companyHonor',{params})
/*省份地址:域名:简称*/
export const myLoginSettingsDetails=data=>axios.post('/smart/regionAbbreviation/myLoginSettingsDetails',data)
/*生态合作伙伴--申请加入*/
export const addServicePlatformSolution=data=>axios.post('/smart/servicePlatformSolution/addServicePlatformSolution',data)
